import React from 'react';

const FlipCard = ({ value, description }) => (
  <div className='flip-card'>
    <div className='flip-card-inner'>
      <div className='flip-card-front'>
        <h2>{value || 'Value'}</h2>
      </div>
      <div className='flip-card-back'>
        <p>{description || 'Description'}</p>
      </div>
    </div>
    <style jsx>{`
      .flip-card {
        background-color: transparent;
        padding: 1rem;
        margin: 1rem;
        width: 400px;
        height: 180px;
        perspective: 1000px;
      }

      .flip-card-inner {
        position: relative;
        width: 100%;
        height: 100%;
        text-align: center;
        transition: transform 0.6s;
        transform-style: preserve-3d;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
      }

      .flip-card:hover .flip-card-inner {
        transform: rotateY(180deg);
      }

      .flip-card-front,
      .flip-card-back {
        position: absolute;
        width: 100%;
        height: 100%;
        -webkit-backface-visibility: hidden;
        backface-visibility: hidden;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0.5rem;
        border-radius: 5px;
      }

      .flip-card-front {
        background-color: #474747;
      }

      .flip-card-back {
        background-color: var(--background-white);
        color: var(--primary-black);
        transform: rotateY(180deg);
      }

      h2 {
        margin: 0;
        font-size: 28px;
        color: var(--primary-color);
      }

      p {
        margin: 0;
        font-size: 14px;
      }

      @media screen and (max-width: 500px) {
        .flip-card {
          width: 300px;
          height: 180px;
        }

        h2 {
          font-size: 24px;
        }

        p {
          font-size: 12px;
        }
      }
    `}</style>
  </div>
);

export default FlipCard;
