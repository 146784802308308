import React from 'react';
import Img from 'gatsby-image';

const Avatar = ({ name, image }) => {
  return (
    <div className='avatar'>
      <Img
        style={{ height: 'auto', width: '100px' }}
        alt={`${name} Members`}
        imgStyle={{ objectFit: 'cover' }}
        fluid={image}
      />
      <p>{name}</p>
      <style jsx>{`
        .avatar {
          margin: 1rem;
        }

        p {
          max-width: 100px;
          word-break: break-word;
          font-size: 18px;
        }
      `}</style>
    </div>
  );
};

export default Avatar;
