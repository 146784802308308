import React from 'react';
import Layout from '../components/Layout';
import Seo from '../components/Seo';
import LogoPZ from '../../assets/images/positive-zero-logo/icon.svg';
import Illustration from '../../assets/illustrations/vision-illustration.svg';
import { useStaticQuery, graphql } from 'gatsby';
import FlipCard from '../components/about/FlipCard';
import Avatar from '../components/about/Avatar';
import Together from '../components/Together';
import withTranslations from '../components/hoc/withTranslations';
import { useTranslation } from 'react-i18next';
import Img from 'gatsby-image';

const About = () => {
  const { t } = useTranslation();

  const values = [
    {
      name: t('about.inTheMiddleName'),
      description: t('about.inTheMiddleDescription')
    },
    {
      name: t('about.qualityName'),
      description: t('about.qualityDescription')
    },
    {
      name: t('about.adaptName'),
      description: t('about.adaptDescription')
    },
    {
      name: t('about.simpleName'),
      description: t('about.simpleDescription')
    },
    {
      name: t('about.listenName'),
      description: t('about.listenDescription')
    },
    {
      name: t('about.overcomeName'),
      description: t('about.overcomeDescription')
    },
    {
      name: t('about.dontMakeName'),
      description: t('about.dontMakeDescription')
    },
    {
      name: t('about.beSpontaneousName'),
      description: t('about.beSpontaneousDescription')
    },
    {
      name: t('about.weLoveNumbersName'),
      description: t('about.weLoveNumbersDescription')
    },
    {
      name: t('about.commitName'),
      description: t('about.commitDescription')
    }
  ];

  let data = useStaticQuery(graphql`
    query {
      alex: file(relativePath: { eq: "members/alex.png" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      andres: file(relativePath: { eq: "members/andres.png" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      fernando: file(relativePath: { eq: "members/fernando.png" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ramses: file(relativePath: { eq: "members/ramses.png" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      sergio: file(relativePath: { eq: "members/sergio.png" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      diego: file(relativePath: { eq: "members/diego.png" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      ramon: file(relativePath: { eq: "members/ramon.png" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      team: file(relativePath: { eq: "members/team.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  const members = [
    { name: 'Fernando Pinedo', image: data.fernando.childImageSharp.fluid },
    { name: 'Alejandro Vilchis', image: data.alex.childImageSharp.fluid },
    { name: 'Sergio Saucedo', image: data.sergio.childImageSharp.fluid },
    { name: 'Ramses Cadena', image: data.ramses.childImageSharp.fluid },
    { name: 'Andres Peña', image: data.andres.childImageSharp.fluid },
    { name: 'Diego Cortés', image: data.diego.childImageSharp.fluid },
    { name: 'Ramón Melchor', image: data.ramon.childImageSharp.fluid }
  ];

  return (
    <Layout section='ABOUT' manualMargin manualPadding>
      <Seo
        title={t('about.seoTitle')}
        description={t('about.seoDescription')}
        keywords={[`about`, `nosotros`, `positive zero`, `valores`, `mision`, `vision`]}
      />

      <div className='global-section container header'>
        <h1 className='gtku font-bold'>{t('about.gtku')}</h1>
        <LogoPZ width='200px' />
      </div>

      <div className='goals'>
        <div className='container goal'>
          <h2 className='font-bold font-color-primary goal-title'>{t('about.whoTitle')}</h2>
          <p className='goal-description'>{t('about.whoText')}</p>
        </div>

        <div className='container goal'>
          <h2 className='font-bold font-color-primary goal-title'>{t('about.whyTitle')}</h2>
          <p className='goal-description'>{t('about.whyText')}</p>
        </div>
      </div>

      <div className='global-section global-light vision'>
        <div className='global-padding global-margin'>
          <h2 className='font-bold vision-title'>{t('about.visionTitle')}</h2>
          <p>{t('about.visionText')}</p>
          <p className='font-light'>2025</p>
          <Illustration />
        </div>
      </div>

      <div className='global-section global-dark values'>
        <div className='global-padding global-margin'>
          <h2 className='font-white'>{t('about.valuesTitle')}</h2>
          <div className='cards'>
            {values.map(value => {
              return <FlipCard key={value.name} value={value.name} description={value.description} />;
            })}
          </div>
        </div>
      </div>
      <div className='global-section container team'>
        <div className='global-padding global-margin'>
          <h2 className='font-white'>{t('about.meetTheTeamTitle')}</h2>
          <p>{t('about.meetTheTeamDescription')}</p>
          <div className='avatars'>
            {members
              .map(member => ({ member, newIndex: Math.random() }))
              .sort((a, b) => a.newIndex - b.newIndex)
              .map(({ member }) => (
                <Avatar key={member.name} name={member.name} image={member.image} />
              ))}
          </div>
          <div className='my-2'>
            <Img
              alt={`Team photo`}
              style={{ maxHeight: 1300 }}
              imgStyle={{ objectFit: 'contain', objectPosition: 'center', pointerEvents: 'none' }}
              fluid={data.team.childImageSharp.fluid}
            />
          </div>
        </div>
      </div>

      <div className='global-section container together'>
        <Together />
      </div>
      <style jsx>{`
        .container {
          display: flex;
          justify-content: center;
          align-items: center;
          margin: auto;
        }

        .vision {
          clip-path: polygon(0 0, 100% 20px, 100% 100%, 0% 100%);
          text-align: center;
        }

        .vision-title {
          color: var(--primary-black);
        }

        .gtku {
          padding: 0.5rem;
          font-size: 92px;
          max-width: 570px;
          margin-right: 2rem;
        }

        .header-text {
          margin-right: 3rem;
        }

        .goals {
          display: flex;
          justify-content: space-around;
          flex-direction: column;
        }

        .goal {
          margin: auto;
          max-width: 700px;
        }

        .goal-title {
          margin-right: 3rem;
          flex: 1;
        }

        .goal-description {
          max-width: 500px;
          padding: 0.5rem;
          flex: 4;
        }

        .vision {
          display: flex;
          padding: 100px;
          flex-direction: column;
          justify-content: center;
          width: 100%;
        }

        .values {
          text-align: center;
        }

        .cards {
          width: 100%;
          display: flex;
          justify-content: center;
          flex-wrap: wrap;
        }

        .team {
          flex-direction: column;
          text-align: center;
        }

        .avatars {
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
        }

        @media screen and (max-width: 678px) {
          .header {
            flex-direction: column;
            text-align: center;
            margin: auto;
          }

          .global-section {
            padding: 30px 0;
          }

          .gtku {
            font-size: 42px;
          }

          h2 {
            margin: 0;
            font-size: 28px;
          }

          p {
            margin: 1rem 0;
            font-size: 15px;
          }

          .goal-title {
            margin: 0;
          }

          .gtku {
            margin-right: 0;
          }

          .goal {
            flex-direction: column;
            text-align: center;
          }

          .avatars {
            justify-content: center;
          }
        }
      `}</style>
    </Layout>
  );
};

export default withTranslations()(About);
